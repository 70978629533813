@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.radiating-circle {
  position: relative;
}

.radiating-circle::before,
.radiating-circle::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  transform: translate(-50%, -50%);
  animation: radiate 2s infinite ease-out;
}

.radiating-circle::after {
  animation-delay: 1s; /* Creates a staggered effect */
}

@keyframes radiate {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}
